import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45route_45guard_45global from "/app/clients/members/middleware/01.routeGuard.global.ts";
import _02_45maybe_45pass_45query_45global from "/app/clients/members/middleware/02.maybePassQuery.global.ts";
import _03_45pass_45language_45query_45global from "/app/clients/members/middleware/03.passLanguageQuery.global.ts";
export const globalMiddleware = [
  validate,
  _01_45route_45guard_45global,
  _02_45maybe_45pass_45query_45global,
  _03_45pass_45language_45query_45global
]
export const namedMiddleware = {}