import type { VNode } from 'vue';
import MainContent from '@/components/MainContent';

export default defineNuxtComponent({
  name: 'AuthLayout',
  setup() {
    useHead({
      htmlAttrs: {
        class: 'bg-rb-light-yellow',
      },
    });
  },
  render(): VNode {
    return <MainContent>{this.$slots.default?.()}</MainContent>;
  },
});
