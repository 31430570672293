import type { ComputedRef } from 'vue';
import type {
  DeliveryWeek,
  DeliveryPlan,
  DeliveryAddressUpdate,
  Subscription,
  UpdateCustomerSubscription,
  DeliverySlot,
  Country,
} from '@ruokaboksi/api-client';
import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/vue-query';
import retry from 'async-await-retry';
import { formatDateUtc } from '@/utils/dateUtils';
import { KEY_MY_CUSTOMER_STATE } from './useCustomerApi';

export const KEY_SUBSCRIPTIONS = 'subscriptions';
export const KEY_DELIVERY_WEEKS = 'deliveryWeeks';
const KEY_DELIVERY = 'delivery';
const KEY_DELIVERY_SLOTS = 'deliverySlots';
const KEY_DELIVERY_AREA = 'deliveryArea';
export const KEY_DELIVERIES_BY_CUSTOMER_ID = 'deliveriesByCustomerId';

/**
 * Composable for fetching information from the _Deliveries_ service.
 */
export default function useDeliveriesApi() {
  const { isLoggedIn } = useCurrentUser();
  const queryClient = useQueryClient();
  const apiClient = useApiClient();

  const getCustomerSubscriptions = () => {
    const queryFn = async () => {
      const response = await apiClient.subscriptions.listByCustomerId({
        customerId: '_me',
      });
      return response.items;
    };
    const queryKey = [KEY_SUBSCRIPTIONS];
    return useQuery({
      queryKey,
      queryFn,
      enabled: isLoggedIn,
      staleTime: 30 * 1000,
    });
  };

  /**
   * Very YAGNI implementation. Polymorphism to allow straight string will prolly be needed at some point.
   */
  const getDeliveryWeeks = (
    subscriptionId: ComputedRef<number | undefined>
  ) => {
    const enabled = computed<boolean>(() => subscriptionId.value !== undefined);
    const queryFn = async () => {
      if (!subscriptionId.value) return [];

      const response = await apiClient.deliveryWeeks.list({
        customerId: '_me',
        subscriptionId: subscriptionId.value,
      });

      return response.items;
    };
    const queryKey = [KEY_DELIVERY_WEEKS];
    return useQuery({
      queryKey,
      staleTime: 30 * 1000,
      queryFn,
      enabled,
      refetchOnWindowFocus: false,
    });
  };

  /**
   * Sets the delivery address for a subscription.
   */
  const setDeliveryAddress = async (
    subscriptionId: number,
    deliveryAddress: DeliveryAddressUpdate
  ) => {
    const response = await apiClient.subscriptions.updateDeliveryAddress({
      customerId: '_me',
      subscriptionId,
      data: deliveryAddress,
    });
    queryClient.invalidateQueries({ queryKey: [KEY_SUBSCRIPTIONS] });
    return response;
  };

  const setWeekPause = async (
    newValue: boolean,
    subscriptionId: number,
    paymentDate: DeliveryPlan['paymentDate']
  ) => {
    const parsedDate = formatDateUtc(paymentDate);

    let response = null;

    if (newValue === false) {
      response = await apiClient.deliveryPlans.unpause({
        customerId: '_me',
        subscriptionId,
        paymentDate: parsedDate,
      });
    } else {
      response = await apiClient.deliveryPlans.pause({
        customerId: '_me',
        subscriptionId,
        paymentDate: parsedDate,
      });
    }

    queryClient.invalidateQueries({ queryKey: [KEY_DELIVERY_WEEKS] });
    return response;
  };

  const setSubscriptionSuspended = async (
    newValue: boolean,
    subscriptionId: number
  ) => {
    let response;
    if (newValue === false) {
      response = await apiClient.subscriptions.unsuspend({
        customerId: '_me',
        subscriptionId,
      });
    } else {
      response = await apiClient.subscriptions.suspend({
        customerId: '_me',
        subscriptionId,
      });
    }

    queryClient.invalidateQueries({ queryKey: [KEY_DELIVERY_WEEKS] });
    queryClient.invalidateQueries({ queryKey: [KEY_SUBSCRIPTIONS] });
    queryClient.invalidateQueries({ queryKey: [KEY_MY_CUSTOMER_STATE] });

    return response;
  };

  const setWeekProducts = async (
    products: { recipes: string[]; additionalProducts: string[] },
    subscriptionId: number,
    paymentDate: DeliveryWeek['paymentDate']
  ) => {
    const parsedDate = formatDateUtc(new Date(paymentDate));
    const response = await apiClient.deliveryPlans.update({
      customerId: '_me',
      subscriptionId,
      paymentDate: parsedDate,
      data: products,
    });

    queryClient.invalidateQueries({ queryKey: [KEY_DELIVERY_WEEKS] });
    return response;
  };

  const setSubscriptionPaymentMethod = async (
    subscriptionId: number,
    billingOptionId: Subscription['billingOptionId']
  ) => {
    if (!billingOptionId) return null;
    const response = await apiClient.subscriptions.updateBillingOption({
      customerId: '_me',
      subscriptionId,
      data: { billingOptionId },
    });
    queryClient.invalidateQueries({ queryKey: [KEY_SUBSCRIPTIONS] });
    queryClient.invalidateQueries({ queryKey: [KEY_MY_CUSTOMER_STATE] });
    return response;
  };

  const getDeliveryById = (deliveryId: ComputedRef<number | undefined>) => {
    const enabled = computed<boolean>(() => deliveryId.value !== undefined);
    const queryFn = async () => {
      if (!deliveryId.value) return null;

      return await apiClient.deliveries.retrieveByCustomerId({
        deliveryId: deliveryId.value,
        customerId: '_me',
      });
    };
    const queryKey = [KEY_DELIVERY, deliveryId];
    return useQuery({
      queryKey,
      queryFn,
      enabled,
      refetchOnWindowFocus: false,
    });
  };

  const getDeliveryAreasForCountry = (country?: Country) => {
    const queryFn = async () => {
      const response = await apiClient.deliveryAreas.list({
        ...(country && { country }),
      });
      return response.items;
    };
    const queryKey = ['deliveryAreas'];
    return useQuery({ queryKey, queryFn });
  };

  /**
   * Updates customer subscriptions.
   */
  const updateCustomerSubscription = async (
    subscriptionId: number,
    body: UpdateCustomerSubscription
  ) => {
    const response = await apiClient.subscriptions.updatePlan({
      customerId: '_me',
      subscriptionId,
      data: body,
    });
    queryClient.invalidateQueries({ queryKey: [KEY_SUBSCRIPTIONS] });
    return response;
  };

  const waitForDeliveryPaymentStatus = async (
    deliveryId: number,
    paymentStatus: string
  ) => {
    try {
      return await retry(
        async (deliveryId: number, paymentStatus: string) => {
          const delivery = await apiClient.deliveries.retrieve({
            deliveryId,
          });
          if (delivery.paymentStatus !== paymentStatus) {
            throw new Error('not yet');
          }
          return delivery;
        },
        [deliveryId, paymentStatus],
        {
          retriesMax: 10,
          interval: 2_000,
          exponential: false,
        }
      );
    } catch (e) {
      // Catch error to prevent console logging here.
      // We retry the request.
    }
  };

  /**
   * Wait for any subscription with a billingOption set.
   * Necessary when saving a new billingOption.
   */
  const waitForDefaultBillingOption = async () => {
    try {
      return await retry(
        async () => {
          const response = await apiClient.subscriptions.listByCustomerId({
            customerId: '_me',
          });
          const subscriptionWithBillingOptionId = response.items.find(
            (sub) => sub.billingOptionId
          );
          if (!subscriptionWithBillingOptionId) {
            throw new Error('not yet');
          }
          return subscriptionWithBillingOptionId.billingOptionId;
        },
        undefined,
        {
          retriesMax: 10,
          interval: 2_000,
          exponential: false,
        }
      );
    } catch (e) {
      // Catch error to prevent console logging here.
      // We retry the request.
    }
  };

  const getDeliveriesByCustomerId = (take: number) => {
    const queryFn = async ({
      pageParam,
    }: {
      pageParam: number | undefined;
    }) => {
      return await apiClient.deliveries.list({
        customerId: '_me',
        take,
        ...(pageParam ? { cursor: pageParam } : {}),
      });
    };
    const queryKey = [KEY_DELIVERIES_BY_CUSTOMER_ID];
    return useInfiniteQuery({
      queryKey,
      queryFn,
      initialPageParam: undefined,
      getNextPageParam: (lastPage) => lastPage.cursor,
    });
  };

  const availableDeliverySlotOrder = [
    'SATURDAY',
    'SUNDAY',
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
  ];

  const getAvailableDeliverySlots = (
    defaultDeliverySlot?: Subscription['defaultDeliverySlot']
  ) => {
    const queryFn = async () => {
      if (!defaultDeliverySlot?.deliveryAreaId) return [];
      const response = await apiClient.deliverySlots.list({
        deliveryAreaId: defaultDeliverySlot?.deliveryAreaId,
      });
      const deliverySlots = response.items;
      const deliverySlotsWithSamePaymentDay = deliverySlots.filter(
        (slot) => slot.paymentDay === defaultDeliverySlot?.paymentDay
      );
      const sortedDeliverySlots = deliverySlotsWithSamePaymentDay.toSorted(
        (a, b) => {
          return (
            availableDeliverySlotOrder.indexOf(a.deliveryDay) -
            availableDeliverySlotOrder.indexOf(b.deliveryDay)
          );
        }
      );

      return sortedDeliverySlots;
    };
    const queryKey = [KEY_DELIVERY_SLOTS];
    return useQuery({
      queryKey,
      queryFn,
      enabled: isLoggedIn && !!defaultDeliverySlot?.deliveryAreaId,
    });
  };

  const changeDeliverySlotForDeliveryPlan = async (
    subscriptionId: Subscription['id'],
    paymentDate: DeliveryPlan['paymentDate'],
    deliverySlot: DeliverySlot['id']
  ): Promise<DeliveryPlan> => {
    const parsedDate = formatDateUtc(paymentDate);
    const response = await apiClient.deliveryPlans.updateDeliverySlot({
      customerId: '_me',
      subscriptionId,
      paymentDate: parsedDate,
      data: { deliverySlotId: deliverySlot },
    });

    queryClient.invalidateQueries({ queryKey: [KEY_DELIVERY_WEEKS] });
    return response;
  };

  const market = useCookie('market');

  const getDeliveryAreaForSubscription = (
    subscription: ComputedRef<Subscription | null>
  ) => {
    const enabled = computed<boolean>(() =>
      Boolean(subscription.value?.defaultDeliverySlot.deliveryAreaId)
    );
    const queryFn = async () => {
      if (!subscription.value?.defaultDeliverySlot.deliveryAreaId)
        return undefined;

      const deliveryArea = await apiClient.deliveryAreas.retrieve({
        deliveryAreaId: subscription.value?.defaultDeliverySlot.deliveryAreaId,
      });
      market.value = deliveryArea.market;
      return deliveryArea;
    };
    const queryKey = [
      KEY_DELIVERY_AREA,
      subscription.value?.defaultDeliverySlot.deliveryAreaId,
    ];
    return useQuery({
      queryKey,
      staleTime: 30 * 1000,
      queryFn,
      enabled,
      refetchOnWindowFocus: false,
    });
  };

  return {
    getCustomerSubscriptions,
    getDeliveryById,
    getDeliveryWeeks,
    setDeliveryAddress,
    getDeliveryAreaForSubscription,
    getDeliveryAreasForCountry,
    setSubscriptionPaymentMethod,
    setSubscriptionSuspended,
    setWeekPause,
    setWeekProducts,
    updateCustomerSubscription,
    waitForDefaultBillingOption,
    waitForDeliveryPaymentStatus,
    getDeliveriesByCustomerId,
    getAvailableDeliverySlots,
    changeDeliverySlotForDeliveryPlan,
  };
}
