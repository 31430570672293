import type { User } from 'firebase/auth';
import { fetchSignInMethodsForEmail, ProviderId } from 'firebase/auth';

/**
 * Composable for fetching information about the current user.
 * @returns The current user and methods related to it.
 * @see /plugins/firebase.client.ts for more details.
 */
export default function useCurrentUser() {
  if (process.server) {
    return {
      currentUser: ref<User | null>(null),
      currentUserEmail: computed<string>(() => ''),
      currentUserUid: computed<null>(() => null),
      hasPasswordQuery: (_?: string) => Promise.resolve(false),
      isLoggedIn: computed<boolean>(() => false),
      setCurrentUser: (_user: User | null) => Promise.resolve(false),
    };
  }

  const { $firebaseAuth, $loggedInUser } = useNuxtApp();

  /** Current Firebase user. */
  const currentUser = computed<User | null>(() => $loggedInUser.value);

  /** Email address of current Firebase user. */
  const currentUserEmail = computed<string>(
    () => currentUser.value?.email || ''
  );

  /** User ID of current Firebase user. */
  const currentUserUid = computed<string>(() => currentUser.value?.uid || '');

  /** @returns `true` the user is currently signed in. */
  const isLoggedIn = computed<boolean>(() => !!$loggedInUser.value);

  /**
   * Return `true` the email has a password enabled.
   *
   */
  const hasPasswordQuery = async (email?: string): Promise<boolean> => {
    const emailValue = email ?? currentUser.value?.email;
    if (!emailValue) return false;

    const signInMethods = await fetchSignInMethodsForEmail(
      $firebaseAuth,
      emailValue
    );
    const METHOD_PASSWORD = ProviderId.PASSWORD;
    return signInMethods.includes(METHOD_PASSWORD);
  };

  /** Sets the current Firebase user. */
  const setCurrentUser = async (user: User | null): Promise<void> => {
    await $firebaseAuth.updateCurrentUser(user);
  };

  return {
    currentUser,
    currentUserEmail,
    currentUserUid,
    hasPasswordQuery,
    isLoggedIn,
    setCurrentUser,
  };
}
