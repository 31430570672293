import type { VNode } from 'vue';
import ErrorNotFound from '@/components/ErrorNotFound';
import ErrorGeneral from '@/components/ErrorGeneral';
import Minimal from '@/layouts/auth';
import sadFaceSrc from '@/assets/images/sad-face.webp';

export default defineNuxtComponent({
  name: 'Error',

  props: {
    error: {
      default: undefined,
      type: Object,
    },
  },
  setup() {
    const { locale, localeProperties } = useI18n();

    useHead({
      htmlAttrs: {
        lang: locale,
      },
      titleTemplate: (titleChunk): string => {
        return titleChunk
          ? `${titleChunk} - ${localeProperties.value.brand}`
          : localeProperties.value.brand;
      },
    });

    return {};
  },
  render(): VNode {
    return (
      <Minimal>
        <div class="bg-rb-light-yellow -m-4 grid min-h-[calc(100vh-14rem)] max-md:pb-[8.5rem] max-md:pt-10 md:-m-7 lg:-m-10 2xl:min-h-[calc(100vh-16rem)]">
          <div class="m-auto grid max-w-[60rem] grid-cols-1 md:grid-cols-2">
            <div class="grid items-center justify-center">
              <img
                alt="Surullinen hymiö lautasella"
                src={sadFaceSrc}
                class="w-[45vw] max-w-[28.75rem] max-md:mb-12"
              />
            </div>
            <div class="grid items-center justify-center text-center">
              <div>
                {this.$props.error?.statusCode === 404 ? (
                  <ErrorNotFound />
                ) : (
                  <ErrorGeneral />
                )}
              </div>
            </div>
          </div>
        </div>
      </Minimal>
    );
  },
});
