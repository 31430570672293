import { Week } from '@ruokaboksi/utils';
import { DeliveryWeekModel, type Subscription } from '@ruokaboksi/api-client';
import { z } from 'zod';

export type DeliveryWeekFromApi = z.infer<typeof DeliveryWeekModel>;

export type DeliveryWeek = DeliveryWeekFromApi & {
  weekString: string;
  weekNumberString: string;
};

export const addWeekProperties = (
  weeks: DeliveryWeekFromApi[],
  subscription: Subscription
): DeliveryWeek[] =>
  weeks.map((week) => {
    return {
      ...week,
      weekString: Week.fromPaymentDate(
        new Date(week.paymentDate),
        subscription.defaultDeliverySlot
      ).toYyyyWww(),
      weekNumberString: Week.fromPaymentDate(
        new Date(week.paymentDate),
        subscription.defaultDeliverySlot
      ).isoWeek.toString(),
    };
  });
