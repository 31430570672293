import { acceptHMRUpdate, defineStore } from 'pinia';
import type { NoticeProps } from '@/components/Notices/Notice';

export interface NoticePropsWithId extends NoticeProps {
  id: string;
}

interface NoticeStore {
  notices: NoticePropsWithId[];
}

/** Initial state for the notice store. */
const initialNoticeStore: NoticeStore = {
  notices: [],
};

/** Return true if the notices are semantically equal. */
const equalsNotices = (notice1: NoticeProps, notice2: NoticeProps): boolean =>
  notice1.text === notice2.text && notice1.type === notice2.type;

/**
 * Store for managing notices.
 * @example
 * ```ts
 * export default defineComponent({
 *   name: 'ComponentName',
 *   setup() {
 *     const noticeStore = useNoticeStore();
 *     noticeStore.addNotice({
 *       text: 'Some error occurred.',
 *       type: 'warning', // 'warning' | 'caution' | 'neutral' | 'success'
 *       dismissable: false, // whether to display a dismiss button
 *       timeout: 3000, // timeout of 0 will never hide the notice
 *     });
 *   },
 * });
 * ```
 */
const useNoticeStore = defineStore('NoticeStore', {
  actions: {
    addNotice(props: NoticeProps): void {
      const duplicateNotice: NoticePropsWithId | undefined = this.notices.find(
        (existingNotice) => equalsNotices(existingNotice, props)
      );
      if (duplicateNotice) {
        // If duplicate notice exists, remove the old one and replace it with new one.
        this.removeNotice(duplicateNotice.id);
      }

      const id = Math.floor(Math.random() * 10000).toString();
      const newNotice = {
        ...props,
        id,
      };
      this.notices.push(newNotice);
      setTimeout(() => {
        this.removeNotice(id);
      }, props.timeout ?? NOTICE_TIMEOUT_DEFAULT);
    },
    removeNotice(id?: string): void {
      if (!id) return;
      this.notices = this.notices.filter((n) => n.id !== id);
    },
    removeAllNotices(): void {
      this.notices = [];
    },
  },
  getters: {
    hasNotice(): boolean {
      return !!this.notices.length;
    },
    getNotices(): NoticePropsWithId[] {
      return this.notices;
    },
  },
  state: (): NoticeStore => ({ ...initialNoticeStore }),
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNoticeStore, import.meta.hot));
}

export default useNoticeStore;
