import type { VNode } from 'vue';

/**
 * This component does three things:
 *
 * 1. Evaluates which scripts will be loaded based on customer preferences.
 * 2. Disables Sentry if the analytics consent has not been given.
 * 4. Establishes a route guard to send page views whenever the
 * route changes, provided the customer has consented to marketing.
 */
export default defineComponent({
  name: 'EvaluateScripts',
  setup() {
    const { isFetchedCurrentCustomer } = useCurrentCustomer();
    const { checkCustobarStatus, sendPageView } = useCustobar();
    const { $sentryDisable } = useNuxtApp();
    const { hasAnalyticsConsent, hasMarketingConsent, hasPreferencesConsent } =
      useTrackingConsent();
    const { checkUserGuidingStatus } = useUserGuiding();

    const route = useRoute();
    const router = useRouter();

    const isLoading = computed<boolean>(() => !isFetchedCurrentCustomer.value);
    const needsData = computed<boolean>(
      () => ![...USER_REQUIRED_WHITELIST, '/sign-out'].includes(route.path)
    );

    // Skips page view if the customer has not consented to marketing
    router.afterEach(sendPageView);

    onMounted(() => {
      if (!needsData.value) return;
      // Disable Sentry after mounting if no consent
      watchEffect(() => {
        if (hasAnalyticsConsent.value === false) {
          $sentryDisable();
        }
      });
    });

    watch([isFetchedCurrentCustomer, hasMarketingConsent], checkCustobarStatus);
    watch(
      [isFetchedCurrentCustomer, hasPreferencesConsent],
      checkUserGuidingStatus
    );

    return { isLoading, needsData };
  },
  render(): VNode {
    return <>{this.$slots.default?.()}</>;
  },
});
