import { default as index2ihl0j99XYMeta } from "/app/clients/members/pages/additional-products/index.tsx?macro=true";
import { default as indexWerIslC91tMeta } from "/app/clients/members/pages/auth-action/index.tsx?macro=true";
import { default as indexnrol9iLNCwMeta } from "/app/clients/members/pages/delivery-calendar/index.tsx?macro=true";
import { default as indexo0Se0z95OpMeta } from "/app/clients/members/pages/existing-user/index.tsx?macro=true";
import { default as index0jIaVaAvblMeta } from "/app/clients/members/pages/index.tsx?macro=true";
import { default as index1QNkk6DjQ4Meta } from "/app/clients/members/pages/more/account/index.tsx?macro=true";
import { default as indexYLej1srAvcMeta } from "/app/clients/members/pages/more/change-language/index.tsx?macro=true";
import { default as indexuAWkSny18TMeta } from "/app/clients/members/pages/more/change-password/index.tsx?macro=true";
import { default as indexRZCx0tM2L0Meta } from "/app/clients/members/pages/more/contact-details/index.tsx?macro=true";
import { default as _91id_93Sf3jSZV2H2Meta } from "/app/clients/members/pages/more/deliveries/[id].tsx?macro=true";
import { default as indexoSFmeLG1TGMeta } from "/app/clients/members/pages/more/deliveries/index.tsx?macro=true";
import { default as index2ikRhGqPsXMeta } from "/app/clients/members/pages/more/index.tsx?macro=true";
import { default as index0ibcC20vZQMeta } from "/app/clients/members/pages/more/notifications/index.tsx?macro=true";
import { default as indexDyYccipxEcMeta } from "/app/clients/members/pages/more/offers/credits/index.tsx?macro=true";
import { default as indexAQxEsBX2YZMeta } from "/app/clients/members/pages/more/offers/index.tsx?macro=true";
import { default as indexlcdHoEQkJUMeta } from "/app/clients/members/pages/more/offers/loyalty/index.tsx?macro=true";
import { default as addZQ2wUVy2U5Meta } from "/app/clients/members/pages/more/payment-methods/add.tsx?macro=true";
import { default as indexcmzxiPMYGZMeta } from "/app/clients/members/pages/more/payment-methods/index.tsx?macro=true";
import { default as indexO2nxkMjOwfMeta } from "/app/clients/members/pages/more/settings/index.tsx?macro=true";
import { default as indexu8dCVdPuzZMeta } from "/app/clients/members/pages/more/subscription/index.tsx?macro=true";
import { default as index1y624Hl0xfMeta } from "/app/clients/members/pages/more/support/index.tsx?macro=true";
import { default as index2RysPSseHiMeta } from "/app/clients/members/pages/new-password/index.tsx?macro=true";
import { default as index258pP9RZWdMeta } from "/app/clients/members/pages/no-subscription/index.tsx?macro=true";
import { default as indexXxp3MjDCYhMeta } from "/app/clients/members/pages/onboarding/add-payment-method/index.tsx?macro=true";
import { default as indexftfAjAiU5fMeta } from "/app/clients/members/pages/onboarding/confirm-address/index.tsx?macro=true";
import { default as index2mZgXlJTLqMeta } from "/app/clients/members/pages/onboarding/index.tsx?macro=true";
import { default as indexGgSjdkK5a4Meta } from "/app/clients/members/pages/onboarding/settings/index.tsx?macro=true";
import { default as indexMsl3wOyQtVMeta } from "/app/clients/members/pages/pay-delivery/index.tsx?macro=true";
import { default as index6r4o9nzbVBMeta } from "/app/clients/members/pages/payment-required/index.tsx?macro=true";
import { default as indexrLrezp2yTQMeta } from "/app/clients/members/pages/recipes/index.tsx?macro=true";
import { default as indexzBxS2aGTJLMeta } from "/app/clients/members/pages/reset-password/index.tsx?macro=true";
import { default as indexh7t2N6jvquMeta } from "/app/clients/members/pages/sign-in-password/index.tsx?macro=true";
import { default as indexTqHEgeQ8P7Meta } from "/app/clients/members/pages/sign-in-send-link/index.tsx?macro=true";
import { default as indexSoNT45cY4MMeta } from "/app/clients/members/pages/sign-in/index.tsx?macro=true";
import { default as indexj2ZIDoQIMUMeta } from "/app/clients/members/pages/sign-out/index.tsx?macro=true";
export default [
  {
    name: index2ihl0j99XYMeta?.name ?? "additional-products",
    path: index2ihl0j99XYMeta?.path ?? "/additional-products",
    meta: index2ihl0j99XYMeta || {},
    alias: index2ihl0j99XYMeta?.alias || [],
    redirect: index2ihl0j99XYMeta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/additional-products/index.tsx").then(m => m.default || m)
  },
  {
    name: indexWerIslC91tMeta?.name ?? "auth-action",
    path: indexWerIslC91tMeta?.path ?? "/auth-action",
    meta: indexWerIslC91tMeta || {},
    alias: indexWerIslC91tMeta?.alias || [],
    redirect: indexWerIslC91tMeta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/auth-action/index.tsx").then(m => m.default || m)
  },
  {
    name: indexnrol9iLNCwMeta?.name ?? "delivery-calendar",
    path: indexnrol9iLNCwMeta?.path ?? "/delivery-calendar",
    meta: indexnrol9iLNCwMeta || {},
    alias: indexnrol9iLNCwMeta?.alias || [],
    redirect: indexnrol9iLNCwMeta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/delivery-calendar/index.tsx").then(m => m.default || m)
  },
  {
    name: indexo0Se0z95OpMeta?.name ?? "existing-user",
    path: indexo0Se0z95OpMeta?.path ?? "/existing-user",
    meta: indexo0Se0z95OpMeta || {},
    alias: indexo0Se0z95OpMeta?.alias || [],
    redirect: indexo0Se0z95OpMeta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/existing-user/index.tsx").then(m => m.default || m)
  },
  {
    name: index0jIaVaAvblMeta?.name ?? "index",
    path: index0jIaVaAvblMeta?.path ?? "/",
    meta: index0jIaVaAvblMeta || {},
    alias: index0jIaVaAvblMeta?.alias || [],
    redirect: index0jIaVaAvblMeta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/index.tsx").then(m => m.default || m)
  },
  {
    name: index1QNkk6DjQ4Meta?.name ?? "more-account",
    path: index1QNkk6DjQ4Meta?.path ?? "/more/account",
    meta: index1QNkk6DjQ4Meta || {},
    alias: index1QNkk6DjQ4Meta?.alias || [],
    redirect: index1QNkk6DjQ4Meta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/more/account/index.tsx").then(m => m.default || m)
  },
  {
    name: indexYLej1srAvcMeta?.name ?? "more-change-language",
    path: indexYLej1srAvcMeta?.path ?? "/more/change-language",
    meta: indexYLej1srAvcMeta || {},
    alias: indexYLej1srAvcMeta?.alias || [],
    redirect: indexYLej1srAvcMeta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/more/change-language/index.tsx").then(m => m.default || m)
  },
  {
    name: indexuAWkSny18TMeta?.name ?? "more-change-password",
    path: indexuAWkSny18TMeta?.path ?? "/more/change-password",
    meta: indexuAWkSny18TMeta || {},
    alias: indexuAWkSny18TMeta?.alias || [],
    redirect: indexuAWkSny18TMeta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/more/change-password/index.tsx").then(m => m.default || m)
  },
  {
    name: indexRZCx0tM2L0Meta?.name ?? "more-contact-details",
    path: indexRZCx0tM2L0Meta?.path ?? "/more/contact-details",
    meta: indexRZCx0tM2L0Meta || {},
    alias: indexRZCx0tM2L0Meta?.alias || [],
    redirect: indexRZCx0tM2L0Meta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/more/contact-details/index.tsx").then(m => m.default || m)
  },
  {
    name: _91id_93Sf3jSZV2H2Meta?.name ?? "more-deliveries-id",
    path: _91id_93Sf3jSZV2H2Meta?.path ?? "/more/deliveries/:id()",
    meta: _91id_93Sf3jSZV2H2Meta || {},
    alias: _91id_93Sf3jSZV2H2Meta?.alias || [],
    redirect: _91id_93Sf3jSZV2H2Meta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/more/deliveries/[id].tsx").then(m => m.default || m)
  },
  {
    name: indexoSFmeLG1TGMeta?.name ?? "more-deliveries",
    path: indexoSFmeLG1TGMeta?.path ?? "/more/deliveries",
    meta: indexoSFmeLG1TGMeta || {},
    alias: indexoSFmeLG1TGMeta?.alias || [],
    redirect: indexoSFmeLG1TGMeta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/more/deliveries/index.tsx").then(m => m.default || m)
  },
  {
    name: index2ikRhGqPsXMeta?.name ?? "more",
    path: index2ikRhGqPsXMeta?.path ?? "/more",
    meta: index2ikRhGqPsXMeta || {},
    alias: index2ikRhGqPsXMeta?.alias || [],
    redirect: index2ikRhGqPsXMeta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/more/index.tsx").then(m => m.default || m)
  },
  {
    name: index0ibcC20vZQMeta?.name ?? "more-notifications",
    path: index0ibcC20vZQMeta?.path ?? "/more/notifications",
    meta: index0ibcC20vZQMeta || {},
    alias: index0ibcC20vZQMeta?.alias || [],
    redirect: index0ibcC20vZQMeta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/more/notifications/index.tsx").then(m => m.default || m)
  },
  {
    name: indexDyYccipxEcMeta?.name ?? "more-offers-credits",
    path: indexDyYccipxEcMeta?.path ?? "/more/offers/credits",
    meta: indexDyYccipxEcMeta || {},
    alias: indexDyYccipxEcMeta?.alias || [],
    redirect: indexDyYccipxEcMeta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/more/offers/credits/index.tsx").then(m => m.default || m)
  },
  {
    name: indexAQxEsBX2YZMeta?.name ?? "more-offers",
    path: indexAQxEsBX2YZMeta?.path ?? "/more/offers",
    meta: indexAQxEsBX2YZMeta || {},
    alias: indexAQxEsBX2YZMeta?.alias || [],
    redirect: indexAQxEsBX2YZMeta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/more/offers/index.tsx").then(m => m.default || m)
  },
  {
    name: indexlcdHoEQkJUMeta?.name ?? "more-offers-loyalty",
    path: indexlcdHoEQkJUMeta?.path ?? "/more/offers/loyalty",
    meta: indexlcdHoEQkJUMeta || {},
    alias: indexlcdHoEQkJUMeta?.alias || [],
    redirect: indexlcdHoEQkJUMeta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/more/offers/loyalty/index.tsx").then(m => m.default || m)
  },
  {
    name: addZQ2wUVy2U5Meta?.name ?? "more-payment-methods-add",
    path: addZQ2wUVy2U5Meta?.path ?? "/more/payment-methods/add",
    meta: addZQ2wUVy2U5Meta || {},
    alias: addZQ2wUVy2U5Meta?.alias || [],
    redirect: addZQ2wUVy2U5Meta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/more/payment-methods/add.tsx").then(m => m.default || m)
  },
  {
    name: indexcmzxiPMYGZMeta?.name ?? "more-payment-methods",
    path: indexcmzxiPMYGZMeta?.path ?? "/more/payment-methods",
    meta: indexcmzxiPMYGZMeta || {},
    alias: indexcmzxiPMYGZMeta?.alias || [],
    redirect: indexcmzxiPMYGZMeta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/more/payment-methods/index.tsx").then(m => m.default || m)
  },
  {
    name: indexO2nxkMjOwfMeta?.name ?? "more-settings",
    path: indexO2nxkMjOwfMeta?.path ?? "/more/settings",
    meta: indexO2nxkMjOwfMeta || {},
    alias: indexO2nxkMjOwfMeta?.alias || [],
    redirect: indexO2nxkMjOwfMeta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/more/settings/index.tsx").then(m => m.default || m)
  },
  {
    name: indexu8dCVdPuzZMeta?.name ?? "more-subscription",
    path: indexu8dCVdPuzZMeta?.path ?? "/more/subscription",
    meta: indexu8dCVdPuzZMeta || {},
    alias: indexu8dCVdPuzZMeta?.alias || [],
    redirect: indexu8dCVdPuzZMeta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/more/subscription/index.tsx").then(m => m.default || m)
  },
  {
    name: index1y624Hl0xfMeta?.name ?? "more-support",
    path: index1y624Hl0xfMeta?.path ?? "/more/support",
    meta: index1y624Hl0xfMeta || {},
    alias: index1y624Hl0xfMeta?.alias || [],
    redirect: index1y624Hl0xfMeta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/more/support/index.tsx").then(m => m.default || m)
  },
  {
    name: index2RysPSseHiMeta?.name ?? "new-password",
    path: index2RysPSseHiMeta?.path ?? "/new-password",
    meta: index2RysPSseHiMeta || {},
    alias: index2RysPSseHiMeta?.alias || [],
    redirect: index2RysPSseHiMeta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/new-password/index.tsx").then(m => m.default || m)
  },
  {
    name: index258pP9RZWdMeta?.name ?? "no-subscription",
    path: index258pP9RZWdMeta?.path ?? "/no-subscription",
    meta: index258pP9RZWdMeta || {},
    alias: index258pP9RZWdMeta?.alias || [],
    redirect: index258pP9RZWdMeta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/no-subscription/index.tsx").then(m => m.default || m)
  },
  {
    name: indexXxp3MjDCYhMeta?.name ?? "onboarding-add-payment-method",
    path: indexXxp3MjDCYhMeta?.path ?? "/onboarding/add-payment-method",
    meta: indexXxp3MjDCYhMeta || {},
    alias: indexXxp3MjDCYhMeta?.alias || [],
    redirect: indexXxp3MjDCYhMeta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/onboarding/add-payment-method/index.tsx").then(m => m.default || m)
  },
  {
    name: indexftfAjAiU5fMeta?.name ?? "onboarding-confirm-address",
    path: indexftfAjAiU5fMeta?.path ?? "/onboarding/confirm-address",
    meta: indexftfAjAiU5fMeta || {},
    alias: indexftfAjAiU5fMeta?.alias || [],
    redirect: indexftfAjAiU5fMeta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/onboarding/confirm-address/index.tsx").then(m => m.default || m)
  },
  {
    name: index2mZgXlJTLqMeta?.name ?? "onboarding",
    path: index2mZgXlJTLqMeta?.path ?? "/onboarding",
    meta: index2mZgXlJTLqMeta || {},
    alias: index2mZgXlJTLqMeta?.alias || [],
    redirect: index2mZgXlJTLqMeta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/onboarding/index.tsx").then(m => m.default || m)
  },
  {
    name: indexGgSjdkK5a4Meta?.name ?? "onboarding-settings",
    path: indexGgSjdkK5a4Meta?.path ?? "/onboarding/settings",
    meta: indexGgSjdkK5a4Meta || {},
    alias: indexGgSjdkK5a4Meta?.alias || [],
    redirect: indexGgSjdkK5a4Meta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/onboarding/settings/index.tsx").then(m => m.default || m)
  },
  {
    name: indexMsl3wOyQtVMeta?.name ?? "pay-delivery",
    path: indexMsl3wOyQtVMeta?.path ?? "/pay-delivery",
    meta: indexMsl3wOyQtVMeta || {},
    alias: indexMsl3wOyQtVMeta?.alias || [],
    redirect: indexMsl3wOyQtVMeta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/pay-delivery/index.tsx").then(m => m.default || m)
  },
  {
    name: index6r4o9nzbVBMeta?.name ?? "payment-required",
    path: index6r4o9nzbVBMeta?.path ?? "/payment-required",
    meta: index6r4o9nzbVBMeta || {},
    alias: index6r4o9nzbVBMeta?.alias || [],
    redirect: index6r4o9nzbVBMeta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/payment-required/index.tsx").then(m => m.default || m)
  },
  {
    name: indexrLrezp2yTQMeta?.name ?? "recipes",
    path: indexrLrezp2yTQMeta?.path ?? "/recipes",
    meta: indexrLrezp2yTQMeta || {},
    alias: indexrLrezp2yTQMeta?.alias || [],
    redirect: indexrLrezp2yTQMeta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/recipes/index.tsx").then(m => m.default || m)
  },
  {
    name: indexzBxS2aGTJLMeta?.name ?? "reset-password",
    path: indexzBxS2aGTJLMeta?.path ?? "/reset-password",
    meta: indexzBxS2aGTJLMeta || {},
    alias: indexzBxS2aGTJLMeta?.alias || [],
    redirect: indexzBxS2aGTJLMeta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/reset-password/index.tsx").then(m => m.default || m)
  },
  {
    name: indexh7t2N6jvquMeta?.name ?? "sign-in-password",
    path: indexh7t2N6jvquMeta?.path ?? "/sign-in-password",
    meta: indexh7t2N6jvquMeta || {},
    alias: indexh7t2N6jvquMeta?.alias || [],
    redirect: indexh7t2N6jvquMeta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/sign-in-password/index.tsx").then(m => m.default || m)
  },
  {
    name: indexTqHEgeQ8P7Meta?.name ?? "sign-in-send-link",
    path: indexTqHEgeQ8P7Meta?.path ?? "/sign-in-send-link",
    meta: indexTqHEgeQ8P7Meta || {},
    alias: indexTqHEgeQ8P7Meta?.alias || [],
    redirect: indexTqHEgeQ8P7Meta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/sign-in-send-link/index.tsx").then(m => m.default || m)
  },
  {
    name: indexSoNT45cY4MMeta?.name ?? "sign-in",
    path: indexSoNT45cY4MMeta?.path ?? "/sign-in",
    meta: indexSoNT45cY4MMeta || {},
    alias: indexSoNT45cY4MMeta?.alias || [],
    redirect: indexSoNT45cY4MMeta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/sign-in/index.tsx").then(m => m.default || m)
  },
  {
    name: indexj2ZIDoQIMUMeta?.name ?? "sign-out",
    path: indexj2ZIDoQIMUMeta?.path ?? "/sign-out",
    meta: indexj2ZIDoQIMUMeta || {},
    alias: indexj2ZIDoQIMUMeta?.alias || [],
    redirect: indexj2ZIDoQIMUMeta?.redirect || undefined,
    component: () => import("/app/clients/members/pages/sign-out/index.tsx").then(m => m.default || m)
  }
]