import type { Campaign } from '@ruokaboksi/api-client';
import { isCampaignActive, Week } from '@ruokaboksi/utils';

/**
 * Campaigns linked to current customer's subscription.
 */
export default function useCustomerCampaigns() {
  const { getLinkedSubscriptionCampaign } = useCampaignsApi();
  const { subscriptionId, subscription } = useCustomerSubscriptions();
  const { selectedWeek } = useDeliveryWeeks();

  const {
    data: campaignLink,
    isFetched: isFetchedCustomerCampaigns,
    isLoading: isLoadingCustomerCampaigns,
  } = getLinkedSubscriptionCampaign(subscriptionId);

  /** Campaign linked to current customer's subscription. */
  const campaign = computed<Campaign | undefined>(
    () => campaignLink.value?.campaign
  );

  /** ID of current campaign linked to the customer's subscription. */
  const campaignId = computed<string>(
    () => campaignLink.value?.campaignId || ''
  );

  /** Start time of current campaign. */
  const campaignStartWeek = computed<Week | null>(() => {
    if (
      !campaignLink.value?.startIsoYear ||
      !subscription.value?.defaultDeliverySlot
    ) {
      return null;
    }
    return new Week(
      campaignLink.value?.startIsoYear,
      campaignLink.value?.startIsoWeek,
      subscription.value?.defaultDeliverySlot
    );
  });

  /** Type of current campaign. */
  const campaignType = computed<Campaign['campaignType'] | undefined>(
    () => campaignLink.value?.campaign.campaignType
  );

  /**
   * @returns `true` the  current customer has a campaign
   * attached to their subscription.
   */
  const hasCampaign = computed<boolean>(
    () =>
      !!campaignLink.value &&
      !!selectedWeek.value &&
      !!subscription.value &&
      isCampaignActive(
        campaignLink.value,
        Week.fromYyyyWww(
          selectedWeek.value,
          subscription.value.defaultDeliverySlot
        )
      )
  );

  return {
    campaign,
    campaignId,
    campaignStartWeek,
    campaignType,
    hasCampaign,
    isFetchedCustomerCampaigns,
    isLoadingCustomerCampaigns,
  };
}
