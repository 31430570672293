import type { ComputedRef } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { Language } from '@ruokaboksi/api-client';
import useApiClient from './useApiClient';

const KEY_CAMPAIGN_LINK_BY_SUBSCRIPTION_ID = 'campaignLinkBySubscriptionId';

/**
 * Composable for fetching campaign information.
 */
export default function useCampaignsApi() {
  const apiClient = useApiClient();
  const { locale } = useI18n();

  const getLinkedSubscriptionCampaign = (
    subscriptionId: ComputedRef<number | undefined>
  ) => {
    const enabled = computed<boolean>(() => !!subscriptionId.value);
    const queryFn = async () => {
      if (!subscriptionId.value) return null;
      // 204 No Content and an empty string is returned if there is no campaign link
      return await apiClient.subscriptions.retrieveSubscriptionCampaign({
        customerId: '_me',
        subscriptionId: subscriptionId.value,
        language: locale.value as Language,
      });
    };

    const queryKey = [KEY_CAMPAIGN_LINK_BY_SUBSCRIPTION_ID, subscriptionId];
    return useQuery({
      queryKey,
      queryFn,
      enabled,
      staleTime: 5 * 1000,
      refetchOnWindowFocus: false,
    });
  };

  return {
    getLinkedSubscriptionCampaign,
  };
}
