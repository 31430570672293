import type { ButtonHTMLAttributes, PropType, VNode } from 'vue';

export default defineComponent({
  name: 'Button',
  props: {
    autofocus: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    id: {
      default: undefined,
      type: String,
    },
    hidden: {
      default: false,
      type: Boolean,
    },
    name: {
      default: undefined,
      type: String,
    },
    onClick: {
      default: defaultEventHandler,
      type: Function as PropType<(event: Event) => void>,
    },
    title: {
      default: undefined,
      type: String,
    },
    tabindex: {
      default: undefined,
      type: String,
    },
    type: {
      default: 'button',
      type: String as PropType<ButtonHTMLAttributes['type']>,
    },
    spinner: {
      default: false,
      type: Boolean,
    },
  },
  render(): VNode {
    return (
      <button
        autofocus={this.autofocus}
        class={{ button: true, spinner: this.spinner }}
        disabled={this.disabled}
        hidden={this.hidden}
        id={this.id}
        name={this.name}
        onClick={this.onClick}
        tabindex={this.tabindex}
        title={this.title}
        type={this.type}
        data-testid={this.name ? `${this.name}-button` : undefined}
      >
        {this.spinner && <span class="spinner"></span>}
        {this.$slots.default?.()}
      </button>
    );
  },
});
