import type { AdditionalProduct, Recipe } from '@ruokaboksi/api-client';
import { acceptHMRUpdate, defineStore } from 'pinia';

export interface ProductModalState {
  id: string;
  product: AdditionalProduct | Recipe | null;
}

export type ProductModalOptions = ProductModalState;

/** Initial state for product modal store. */
const initialProductModalStore: ProductModalState = {
  id: '',
  product: null,
};

/** Store for managing modals. */
const useProductModalStore = defineStore('ProductModalStore', {
  actions: {
    close(): void {
      this.id = '';
      this.product = null;
    },
    open(options: ProductModalOptions): void {
      this.id = options.id || '';
      this.product = options.product || null;
    },
  },
  getters: {
    isModalVisible(state: ProductModalState): boolean {
      return state.product !== null;
    },
  },
  state: (): ProductModalState => ({ ...initialProductModalStore }),
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useProductModalStore, import.meta.hot)
  );
}

export default useProductModalStore;
