import type { PriceTier } from '@ruokaboksi/api-client';
import { Price, ZERO_PRICE } from '@ruokaboksi/utils';
import type { DeliveryWeek } from '@/models';
import { addWeekProperties } from '@/models';

/**
 * Composable for fetching information related to delivery weeks.
 */
export default function useDeliveryWeeks() {
  const { subscriptionId, subscription } = useCustomerSubscriptions();
  const { getDeliveryWeeks } = useDeliveriesApi();
  const { getBoxTypeById } = useBoxTypeApi();
  const route = useRoute();

  const {
    data: initialWeeks,
    isFetched: isFetchedDeliveryWeeks,
    isLoading: isLoadingDeliveryWeeks,
  } = getDeliveryWeeks(subscriptionId);

  /**
   * Array of available delivery weeks including the
   * past delivery week at index zero.
   */
  const deliveryWeeks = computed<DeliveryWeek[] | null>(() =>
    isFetchedDeliveryWeeks.value &&
    initialWeeks.value?.length &&
    subscription.value
      ? addWeekProperties(initialWeeks.value, subscription.value)
      : null
  );

  /** Price tiers of the current selected box type. */
  const priceTiers = computed<PriceTier[]>(
    () => boxType.data.value?.priceTiers ?? []
  );

  /** Maximum amount of recipes in the current price tier. */
  const maxRecipes = computed<number>(() =>
    priceTiers.value?.length
      ? Math.max(...priceTiers.value.map((i) => i.recipeCount))
      : 0
  );

  /** Minimum amount of recipes in the current price tier. */
  const minRecipes = computed<number>(() =>
    priceTiers.value?.length
      ? Math.min(...priceTiers.value.map((i) => i.recipeCount))
      : 0
  );

  /** Delivery week based on the current route query string. */
  const selectedDeliveryWeek = computed<DeliveryWeek | undefined>(() =>
    deliveryWeeks.value?.find(
      (w) => w.weekString === String(route.query.selectedWeek)
    )
  );

  /**
   * Index number of the selected delivery week in
   * the array that holds all delivery weeks.
   */
  const selectedDeliveryWeekIndex = computed<number | undefined>(() =>
    deliveryWeeks.value?.findIndex(
      (w) => w.weekString === String(route.query.selectedWeek)
    )
  );

  /**
   * The selected delivery week number as a string.
   * @example '42'
   */
  const selectedDeliveryWeekNumberString = computed<string>(() => {
    return selectedDeliveryWeek?.value?.weekNumberString || '';
  });

  /** The box type ID of selected delivery week. */
  const boxTypeId = computed<string>(
    () => selectedDeliveryWeek.value?.boxTypeId || ''
  );

  /** The box type of selected delivery week. */
  const boxType = getBoxTypeById(boxTypeId);

  /** Delivery date of the selected delivery week. */
  const deliveryDate = computed<Date | string>(
    () => selectedDeliveryWeek?.value?.deliveryDate ?? ''
  );

  /** Initial delivery week when route query is missing. */
  const initialWeek = computed<DeliveryWeek | null>(() =>
    deliveryWeeks.value && deliveryWeeks.value.length >= 2
      ? deliveryWeeks.value[2]
      : null
  );

  /** @returns `true` if selected delivery week is paused. */
  const isSelectedDeliveryWeekPaused = computed<boolean>(
    () => !!selectedDeliveryWeek.value?.paused
  );

  /** @returns `true` if selected delivery week can be edited. */
  const isSelectedDeliveryWeekEditable = computed<boolean>(
    () => !!selectedDeliveryWeek.value?.editable
  );

  /** @returns `true` if the deliveryWeek can be paused or unpaused. */
  const isSelectedDeliveryWeekPauseEditable = computed<boolean>(
    () => !selectedDeliveryWeek.value?.locked
  );

  const selectedWeek = computed<string>(
    () =>
      validatedQueryWeekString.value ||
      selectedDeliveryWeek.value?.weekString ||
      initialWeek.value?.weekString ||
      ''
  );

  /** Discount for selected delivery week. */
  const selectedDeliveryWeekDiscount = computed<number>(() =>
    Math.max(selectedDeliveryWeek.value?.totalPrice?.discount || 0, 0)
  );

  /** Available credit for selected delivery week. */
  const selectedDeliveryWeekCredit = computed<number>(() =>
    Math.max(selectedDeliveryWeek.value?.totalPrice?.credit || 0, 0)
  );

  /** Recipe count for selected delivery week. */
  const selectedDeliveryWeekRecipeCount = computed<number>(
    () => selectedDeliveryWeek.value?.recipes?.length || 0
  );

  const priceTier = computed<PriceTier | undefined>(() =>
    boxType.data.value?.priceTiers?.find(
      (tier) => selectedDeliveryWeek.value?.recipes?.length === tier.recipeCount
    )
  );

  const selectedDeliveryWeekPrice = computed<Price>(() => {
    if (!selectedDeliveryWeek.value) {
      return ZERO_PRICE;
    }

    return new Price(selectedDeliveryWeek.value?.totalPrice);
  });

  /** @returns `true` if discounted total differs from total price. */
  const hasDiscount = computed<boolean>(() =>
    selectedDeliveryWeekPrice.value.hasPriceModifier()
  );

  /**
   * Potential delivery week as a string after validation.
   * @returns Empty string if no match is found.
   */
  const validatedQueryWeekString = computed<string>(() =>
    route.query.selectedWeek && deliveryWeeks.value
      ? deliveryWeeks.value
          .map(({ weekString }) => weekString)
          .slice(0, SELECTOR_WEEKS_AMOUNT)
          .find((w) => w === route.query.selectedWeek?.toString()) || ''
      : ''
  );

  return {
    boxType,
    boxTypeId,
    deliveryDate,
    deliveryWeeks,
    hasDiscount,
    initialWeek,
    isFetchedDeliveryWeeks,
    isLoadingDeliveryWeeks,
    isSelectedDeliveryWeekEditable,
    isSelectedDeliveryWeekPauseEditable,
    isSelectedDeliveryWeekPaused,
    maxRecipes,
    minRecipes,
    priceTier,
    priceTiers,
    selectedDeliveryWeek,
    selectedDeliveryWeekDiscount,
    selectedDeliveryWeekCredit,
    selectedDeliveryWeekIndex,
    selectedDeliveryWeekNumberString,
    selectedDeliveryWeekPrice,
    selectedDeliveryWeekRecipeCount,
    selectedWeek,
  };
}
