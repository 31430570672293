import { currencyFromCountryMap, defaultCountry } from '@ruokaboksi/api-client';
import { formatPrice, currencyMap } from '@ruokaboksi/utils';
import useCurrentCustomer from './useCurrentCustomer';

export const useCustomerCurrency = () => {
  const customer = useCurrentCustomer();

  const currency = computed<string>(() => {
    return currencyFromCountryMap[
      customer?.currentCustomer.value?.billingAddress?.country ?? defaultCountry
    ];
  });

  return {
    currency,
    formatPrice: (price: number | undefined) =>
      formatPrice(price, currency.value as keyof typeof currencyMap),
  };
};
