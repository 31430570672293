import type { Recipe } from '@ruokaboksi/api-client';
import { storeToRefs } from 'pinia';

/** Closes the modal when the user hits escape. */
const closeModalOnEscape = (event: KeyboardEvent): void => {
  const productModal = useProductModalStore();
  const { isModalVisible } = storeToRefs(productModal);
  if (!isModalVisible.value || event.code !== 'Escape') return;
  event.preventDefault();
  productModal.close();
};

/**
 * Closes the modal window when the user clicks
 * on a specific element.
 */
const closeModalOnClick = (event: Event): void => {
  const productModal = useProductModalStore();
  event.preventDefault();
  productModal.close();
};

/**
 * Closes the modal window when the user clicks
 * somewhere outside the content area of the product modal.
 */
const closeModalOnBackdrop = (event: Event): void => {
  const target = event.target as HTMLElement;
  if (!target.classList.contains('product-modal')) return;
  const productModal = useProductModalStore();
  productModal.close();
};

/** Fields required for image labels. */
const requiredImageFields = (
  label:
    | Recipe['allergens'][number]
    | Recipe['diets'][number]
    | Recipe['recipeLabels'][number]
): boolean => !!(label.image?.sizes?.thumbnail && label.title);

export {
  closeModalOnBackdrop,
  closeModalOnClick,
  closeModalOnEscape,
  requiredImageFields,
};
