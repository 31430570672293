import type { VNode } from 'vue';
import './Modal.css';

export default defineComponent({
  name: 'Modal',
  setup() {
    const modalStore = useModalStore();
    const wrapperClasses = ['modal-wrapper', { visible: modalStore.isVisible }];

    function handleCloseModal(event: Event): void {
      event.preventDefault();
      modalStore.close();
    }

    onMounted(() => {
      window.addEventListener('keydown', (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          modalStore.close();
        }
      });
    });

    return {
      modalStore,
      handleCloseModal,
      wrapperClasses,
    };
  },
  render(): VNode {
    return (
      <div>
        <input
          class="modal-toggle"
          id="modal-toggle"
          checked={this.modalStore.isVisible}
          type="checkbox"
        />
        <label
          class="modal-backdrop"
          for="modal-toggle"
          onClick={this.handleCloseModal}
        />
        <aside
          aria-describedby="modal-content"
          aria-labelledby="modal-title"
          aria-modal="true"
          class="modal"
          id="modal"
          role="alertdialog"
        >
          <div class="modal-box">
            {this.modalStore.title && (
              <header class="modal-header">
                <h2 class="modal-title" id="modal-title">
                  {this.modalStore.title}
                </h2>
              </header>
            )}
            <div class="modal-content">{this.modalStore.content}</div>
            {this.modalStore.actions && (
              <footer class="modal-action">{this.modalStore.actions}</footer>
            )}
          </div>
        </aside>
      </div>
    );
  },
});
