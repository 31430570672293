import type { Ref } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';
import type { TrackingConsent } from '@/models';

export default function useTrackingConsent() {
  const { currentCustomer, isFetchedCurrentCustomer } = useCurrentCustomer();
  const queryClient = useQueryClient();
  const apiClient = useApiClient();

  const hasAnalyticsConsent = computed<boolean | null>(
    () => hasConsentGeneric('trackingConsentAnalytics').value
  );

  const hasMarketingConsent = computed<boolean | null>(
    () => hasConsentGeneric('trackingConsentMarketing').value
  );

  const hasPreferencesConsent = computed<boolean | null>(
    () => hasConsentGeneric('trackingConsentPreferences').value
  );

  /**
   * Internal implementation of has<X>Consent.
   */
  const hasConsentGeneric = (
    fieldName: keyof TrackingConsent
  ): Ref<boolean | null> => {
    if (!isFetchedCurrentCustomer.value) return ref(null);
    const data = currentCustomer.value;
    if (!data || data[fieldName] === undefined) return ref(null);

    return ref(data[fieldName] as boolean | null);
  };

  const saveConsent = async (newConsent: TrackingConsent) => {
    await apiClient.customers.update({
      data: newConsent,
      customerId: '_me',
    });
    queryClient.invalidateQueries({ queryKey: [KEY_MY_CUSTOMER_INFO] });
    await queryClient.invalidateQueries({ queryKey: [KEY_MY_CUSTOMER_STATE] });
  };

  return {
    hasAnalyticsConsent,
    hasMarketingConsent,
    hasPreferencesConsent,
    saveConsent,
  };
}
