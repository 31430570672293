import type { PropType, VNode } from 'vue';
import type { NutritionInfo } from '@ruokaboksi/api-client';

export default defineComponent({
  name: 'NutritionInfo',
  props: {
    nutritionInfo: {
      default: undefined,
      type: Object as PropType<NutritionInfo>,
    },
    serves: {
      default: 0,
      type: Number,
    },
  },
  setup(props) {
    /**
     * Wraps each word in a span and gives the first
     * one font-weight.
     *
     * Adds a space at the end to separate the next word.
     */
    const displayNutritionValue = (s: string): VNode[] =>
      s.split(' ').map((s, i, words) => (
        <>
          <span class={{ 'font-medium': i === 0 }}>{s}</span>
          {i === words.length - 1 ? '' : ' '}
        </>
      ));

    /** Determines if the component should be rendered. */
    const hasContent = computed(
      () =>
        !!(
          props.nutritionInfo &&
          !!(
            props.nutritionInfo.carbohydratesTotal ||
            props.nutritionInfo.fatTotal ||
            props.nutritionInfo.kcalTotal ||
            props.nutritionInfo.proteinTotal
          )
        )
    );

    return {
      displayNutritionValue,
      hasContent,
    };
  },
  render(): VNode | null {
    if (!this.nutritionInfo || !this.hasContent) return null;
    const { carbohydratesTotal, fatTotal, kcalTotal, proteinTotal } =
      this.nutritionInfo;
    return (
      <dl
        class="flex w-full max-w-[20rem] flex-wrap text-base"
        title={this.$t('nutrition_info.title')}
      >
        {kcalTotal ? (
          <>
            <dt class="w-[50%] overflow-hidden">
              {this.$t('nutrition_info.energy')}
            </dt>
            <dd>
              {this.displayNutritionValue(
                formatNutritionValue(
                  this.nutritionInfo.kcalTotal / this.serves,
                  'kcal'
                )
              )}
            </dd>
          </>
        ) : null}
        {fatTotal ? (
          <>
            <dt class="w-[50%] overflow-hidden">
              {this.$t('nutrition_info.fat')}
            </dt>
            <dd>
              {this.displayNutritionValue(
                formatNutritionValue(
                  this.nutritionInfo.fatTotal / this.serves,
                  'g'
                )
              )}
            </dd>
          </>
        ) : null}
        {carbohydratesTotal ? (
          <>
            <dt class="w-[50%] overflow-hidden">
              {this.$t('nutrition_info.carbs')}
            </dt>
            <dd>
              {this.displayNutritionValue(
                formatNutritionValue(
                  this.nutritionInfo.carbohydratesTotal / this.serves,
                  'g'
                )
              )}
            </dd>
          </>
        ) : null}
        {proteinTotal ? (
          <>
            <dt class="w-[50%] overflow-hidden">
              {this.$t('nutrition_info.protein')}
            </dt>
            <dd>
              {this.displayNutritionValue(
                formatNutritionValue(
                  this.nutritionInfo.proteinTotal / this.serves,
                  'g'
                )
              )}
            </dd>
          </>
        ) : null}
      </dl>
    );
  },
});
