export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"href":"/apple-touch-icon.png","rel":"apple-touch-icon","type":"image/png"},{"href":"/apple-touch-icon.svg","rel":"apple-touch-icon","type":"image/svg+xml"},{"href":"/favicon.ico","rel":"icon","type":"image/x-icon"},{"href":"/favicon.png","rel":"icon","sizes":"any","type":"image/png"},{"href":"/favicon.svg","rel":"icon","sizes":"any","type":"image/svg+xml"},{"href":"https://cdn.sanity.io/","rel":"dns-prefetch"},{"href":"https://identitytoolkit.googleapis.com/","rel":"preconnect"},{"href":"https://js.stripe.com/","rel":"dns-prefetch"},{"href":"https://m.stripe.com/","rel":"dns-prefetch"},{"href":"https://m.stripe.network/","rel":"dns-prefetch"},{"crossorigin":"use-credentials","href":"/manifest.webmanifest","rel":"manifest"}],"style":[],"script":[],"noscript":[]}

export const appBuildAssetsDir = "/_nuxt_01/"

export const appBaseURL = "/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null