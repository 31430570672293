import type { NuxtConfig } from '@nuxt/schema';

const czGtmContainerId = process.env.NUXT_PUBLIC_TAG_MANAGER_ID_CZ;
const fiGtmContainerId = process.env.NUXT_PUBLIC_TAG_MANAGER_ID_FI;

export const i18n = {
  locales: [
    {
      code: 'fi',
      titleKey: 'language.finnish',
      iso: 'fi',
      file: 'fi.json',
      domain: 'members.ruokaboksi.fi',
      gtmContainerId: fiGtmContainerId,
      domainDefault: true,
      brand: 'Ruokaboksi',
      loyaltyProgramName: 'Kokkiklubi',
    },
    {
      code: 'en',
      iso: 'en',
      titleKey: 'language.english',
      file: 'en.json',
      domain: 'members.yummybox.cz',
      gtmContainerId: czGtmContainerId,
      brand: 'Yummy',
      loyaltyProgramName: null,
    },
    {
      code: 'cs',
      iso: 'cs',
      titleKey: 'language.czech',
      file: 'cs.json',
      domain: 'members.yummybox.cz',
      gtmContainerId: czGtmContainerId,
      domainDefault: true,
      brand: 'Yummy',
      loyaltyProgramName: null,
    },
  ],
  defaultLocale: 'fi',
  strategy: 'no_prefix' as const,
  detectBrowserLanguage: false,
  // differentDomains: true, // https://i18n.nuxtjs.org/docs/guide/different-domains
  lazy: true,
  langDir: 'locales',
  vueI18n: './i18n.config.ts',
} as const satisfies NuxtConfig['i18n'];
