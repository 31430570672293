import { z } from 'zod';
import {
  CustomerModel,
  Customer,
  BillingAddressUpdate,
} from '@ruokaboksi/api-client';

const TrackingConsentModel = CustomerModel.pick({
  trackingConsentAnalytics: true,
  trackingConsentMarketing: true,
  trackingConsentPreferences: true,
});

export type TrackingConsent = z.infer<typeof TrackingConsentModel>;

export type CustomerFormData = Pick<
  Customer,
  'email' | 'firstName' | 'lastName' | 'phone' | 'language'
> & {
  billingAddress: BillingAddressUpdate;
  id?: string;
};
