import {
  VueQueryPlugin,
  QueryClient,
  type VueQueryPluginOptions,
} from '@tanstack/vue-query';

export default defineNuxtPlugin((nuxtApp) => {
  const client = new QueryClient();
  const vueQueryPluginOptions: VueQueryPluginOptions = {
    queryClient: client,
  };
  nuxtApp.vueApp.use(VueQueryPlugin, vueQueryPluginOptions);
  return {
    provide: {
      queryClient: client,
    },
  };
});
