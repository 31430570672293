import revive_payload_client_YTGlmQWWT5 from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_VeD7Ba96AP from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_xKuw1km5sV from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/clients/members/.nuxt/components.plugin.mjs";
import unhead_neSs9z3UJp from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_8FoqvPFTot from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_dOTnCc6TUQ from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import pwa_client_V4UTG2qgGR from "/app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import chunk_reload_client_SeG0EjL5Ec from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import firebase_client_zXNDa0wxFH from "/app/clients/members/plugins/firebase.client.ts";
import pwa_client_PLDhtd2phb from "/app/clients/members/plugins/pwa.client.ts";
import sentry_client_shVUlIjFLk from "/app/clients/members/plugins/sentry.client.ts";
import vue_query_wrmMkNpEpe from "/app/clients/members/plugins/vue-query.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  router_VeD7Ba96AP,
  plugin_vue3_xKuw1km5sV,
  components_plugin_KR1HBZs4kY,
  unhead_neSs9z3UJp,
  prefetch_client_8FoqvPFTot,
  i18n_dOTnCc6TUQ,
  pwa_client_V4UTG2qgGR,
  chunk_reload_client_SeG0EjL5Ec,
  firebase_client_zXNDa0wxFH,
  pwa_client_PLDhtd2phb,
  sentry_client_shVUlIjFLk,
  vue_query_wrmMkNpEpe
]